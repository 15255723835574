<template>
  <div>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="job_post_header"
          :table-data="job_post_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          :actionViewRouteLink="actionViewRouteLink"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'

export default {
  name: 'JobPost',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    job_post_header: [],
    job_post_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
    actionViewRouteLink: 'JobPostDetail',
  }),
  methods: {
    initializeHeader() {
      this.job_post_header = [
        {
          text: this.$t('job_post.create_date'),
          value: 'create_date',
        },
        {
          text: this.$t('job_post.job_id'),
          value: 'id',
        },
        {
          text: this.$t('job_post.customer_id'),
          value: 'customer_id',
        },
        {
          text: this.$t('job_post.customer_first_name'),
          value: 'customer_first_name',
        },
        {
          text: this.$t('job_post.customer_last_name'),
          value: 'customer_last_name',
        },
        {
          text: this.$t('job_post.job_title'),
          value: 'job_title',
        },
        {
          text: this.$t('job_post.job_type'),
          value: 'job_type',
        },
        {
          text: this.$t('job_post.status'),
          value: 'status',
        },
        { text: '', value: 'actionView' },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit }
        let params = new URLSearchParams()
        params.set('cms_get_job', JSON.stringify(send_data))
        let get_job_data = await getData(params)
        this.$func.log('-----Get Job Data-----')
        this.$func.log(get_job_data)

        if (!Array.isArray(get_job_data)) {
          this.total_items = get_job_data.total

          let job_data = get_job_data.data

          let customer_id_list = job_data.map(obj => {
            return obj.user_id
          })

          params = new URLSearchParams()
          params.set('cms_get_user', JSON.stringify({ user_id_list: customer_id_list }))
          let get_customer_data = await getData(params)
          this.$func.log('-----Get Customer Data-----')
          this.$func.log(get_customer_data)
          let customer_data = get_customer_data.data

          let temp = []

          for (let i = 0; i < job_data.length; i++) {
            let temp_data = {
              create_date: job_data[i].create_date,
              id: job_data[i].id,
              customer_id: job_data[i].user_id,
              job_title: job_data[i].job_title,
              status: job_data[i].status,
            }

            if (job_data[i].private) {
              temp_data.job_type = 'private'
            } else {
              temp_data.job_type = job_data[i].job_type
            }

            let customer = customer_data.filter(obj => {
              return obj.id == job_data[i].user_id
            })

            if (customer.length > 0) {
              temp_data.customer_first_name = customer[0].first_name
              temp_data.customer_last_name = customer[0].last_name
            }

            temp.push(temp_data)
          }

          this.job_post_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage
      this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>